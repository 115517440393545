
import { computed, defineComponent, reactive, toRefs } from 'vue';
import { GridContent } from '@/components';
import { useStore } from 'vuex';
import Info from '@/views/list/basic-list/info.vue';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { queryActivities, queryProjectNotice } from '@/api/dashboard/workplace';

export default defineComponent({
  name: 'Workplace2',
  setup() {
    const store = useStore();
    const state = reactive({
      tabList: [
        { key: 'my', tab: '我的' },
        { key: 'fov', tab: '我的收藏' },
      ],
    });
    const { context: activities } = useFetchData(() => {
      return queryActivities().then(res => {
        return {
          data: res,
          total: 100,
        };
      });
    });
    const { context: projects } = useFetchData(() => {
      return queryProjectNotice().then(res => {
        return {
          data: res,
          total: 100,
        };
      });
    });
    return {
      currentUser: computed(() => store.getters[`user/currentUser`]),
      activities,
      projects,

      ...toRefs(state),
    };
  },
  components: { GridContent, Info },
});
